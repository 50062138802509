import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "flowbite";
import { Provider } from "react-redux";
import { store } from "store";
import setAuthorizationHeader from "config/setAuthorizationHeader";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "react-hot-toast";
import { ToastContainer } from "react-toastify";

const session = JSON.parse(localStorage.getItem("BBK:token"))
  ? JSON.parse(localStorage.getItem("BBK:token"))
  : null;
if (session) {
  console.log(session);
  setAuthorizationHeader(session.access_token);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
    <Toaster
      containerClassName="main-toaster"
      toastOptions={{ duration: 3000 }}
    />
    <ToastContainer />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
