import { configureStore } from "@reduxjs/toolkit";
import checkoutReducer from "./features/checkoutSlice";
import authReducer from "./features/authSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    checkout: checkoutReducer,
  },
});
