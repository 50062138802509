import { toast } from "react-hot-toast";
import setAuthorizationHeader from "./setAuthorizationHeader";
import authAPI from "../api/authAPI";
import ApiClient from "./ApiClient";

export default async function errorHandler(error) {
  if (error) {
    let message;
    if (error.response) {
      const originalRequest = error.config;
      if (error.response.status === 500)
        message = "Something went terribly wrong";
      else if (error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        const session = localStorage["BBK:token"]
          ? JSON.parse(localStorage["BBK:token"])
          : null;
        return authAPI
          .refresh()
          .then((res) => {
            if (res.data) {
              const accessToken = res.data.access_token;
              setAuthorizationHeader(accessToken);
              localStorage.setItem(
                "BBK:token",
                JSON.stringify({
                  ...session,
                  access_token: accessToken,
                })
              );

              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${accessToken}`;

              return ApiClient(originalRequest);
            } else {
              window.location.href = "/login";
              localStorage.removeItem("BBK:token");
            }
          })
          .catch((err) => {
            if (err.response.status == 403 || err.response.status == 401) {
              window.location.href = "/login";
              localStorage.removeItem("BBK:token");
            }
            message = "Terjadi error! Silahkan coba kembali";
            // message = err.response.data.message;
          });
      } else message = error.response.data.message;

      if (typeof message === "string")
        toast.error(message, { id: "main-error-toast" });

      return Promise.reject(error);
    }
  }
}
