import "./App.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import loadable from "@loadable/component";

import {
  getCourseDetail,
  getCourses,
  getLearningCourse,
  getMyCourses,
} from "api/courseAPI";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAuth } from "store/features/authSlice";
import {
  getDetailTransactions,
  getMyFiles,
  getMyTransactions,
} from "api/userAPI";
import { setAuthorizationHeader } from "config/ApiClient";
import { getProduct } from "api/productAPI";

const Home = loadable(() => import("./pages/Home/index"));
const OnlineCourse = loadable(() => import("./pages/Services/OnlineCourse"));
const Webinar = loadable(() => import("./pages/Services/Webinar"));
const Books = loadable(() => import("./pages/Services/Books"));
const Detail = loadable(() => import("./pages/Detail"));
const Checkout = loadable(() => import("./pages/Checkout"));
const Dashboard = loadable(() => import("./pages/Admin/Dashboard/index"));
const Login = loadable(() => import("./pages/Auth/Login"));
const Register = loadable(() => import("./pages/Auth/Register"));

const Logout = loadable(() => import("./pages/Auth/Logout"));
const NotFound404 = loadable(() => import("./pages/Errors/NotFound404"));
const LearningArea = loadable(() => import("./pages/Admin/LearningArea"));
const MyFiles = loadable(() => import("./pages/Admin/MyFiles"));
const MyTransactions = loadable(() => import("./pages/Admin/MyTransactions"));
const DetailTransaction = loadable(() =>
  import("./pages/Admin/MyTransactions/DetailTransaction")
);
const EditProfile = loadable(() => import("./pages/Admin/EditProfile"));
const Certificate = loadable(() => import("./pages/Admin/Certificate"));
const DetailWebinar = loadable(() => import("./pages/Detail/webinar"));
const DetailBook = loadable(() => import("./pages/Detail/book"));
const MyProducts = loadable(() => import("./pages/Admin/MyProducts"));
const MyProductDetail = loadable(() =>
  import("./pages/Admin/MyProducts/Detail")
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    loader: async () => {
      window.location.assign("/login");
    },
    errorElement: <NotFound404 />,
  },
  {
    path: "/services",
    children: [
      {
        path: "online-course",
        element: <OnlineCourse />,
        errorElement: <NotFound404 />,
        loader: async () => {
          const response = await getCourses();
          if (response.success) {
            return {
              courses: response.data,
            };
          }
        },
      },
      {
        path: "webinar",
        element: <Webinar />,
      },
      {
        path: "books",
        element: <Books />,
      },
    ],
  },
  {
    path: "/detail/:slug",
    element: <Detail />,
    loader: async ({ params }) => {
      const response = await getCourseDetail(params.slug);
      if (response.success) {
        return {
          course: response.data,
        };
      }
    },
    errorElement: <NotFound404 />,
  },
  {
    path: "/webinar/:slug",
    element: <DetailWebinar />,
    loader: async ({ params }) => {
      try {
        const response = await getProduct(params.slug);
        if (response.success) {
          return {
            product: response.data,
          };
        }
      } catch (error) {
        console.log(error);
      }
    },
    errorElement: <NotFound404 />,
  },
  {
    path: "/book/:slug",
    element: <DetailBook />,
    loader: async ({ params }) => {
      try {
        const response = await getProduct(params.slug);
        if (response.success) {
          return {
            product: response.data,
          };
        }
      } catch (error) {
        console.log(error);
      }
    },
    errorElement: <NotFound404 />,
  },
  {
    path: "/checkout/:slug",
    element: <Checkout />,
  },
  {
    path: "/member-area",

    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "files",
        element: <MyFiles />,
        loader: async () => {
          const response = await getMyFiles();
          return response;
        },
        errorElement: <NotFound404 />,
      },
      {
        path: "my-products",
        element: <MyProducts />,
        errorElement: <NotFound404 />,
      },
      {
        path: "my-products/:slug/detail",
        element: <MyProductDetail />,
        errorElement: <NotFound404 />,
      },
      {
        path: "edit-profile",
        element: <EditProfile />,
      },
      {
        path: "transactions",

        children: [
          {
            path: "",
            element: <MyTransactions />,
            loader: async () => {
              const response = await getMyTransactions();
              if (response.statusCode == 200) return response;
            },
            errorElement: <NotFound404 />,
          },
          {
            path: ":order_code",
            element: <DetailTransaction />,
            loader: async ({ params }) => {
              const order_code = params.order_code;
              const response = await getDetailTransactions(order_code);
              return response;
            },
            errorElement: <NotFound404 />,
          },
        ],
      },
    ],
  },
  {
    path: "/member-area/:slug/learning-area",
    element: <LearningArea />,
    loader: async ({ params }) => {
      const slug = params.slug;
      const response = await getLearningCourse(slug);
      return response;
    },
    errorElement: <NotFound404 />,
  },
  {
    path: "/member-area/certificate/:code",
    element: <Certificate />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/auth/logout",
    element: <Logout />,
  },
]);

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    setAuthorizationHeader();
    if (JSON.parse(localStorage.getItem("BBK:token"))) {
      dispatch(setAuth(true));
    }
  }, [dispatch]);

  return <RouterProvider router={router} />;
}

export default App;
