import ApiClient from "./ApiClient";

const setAuthorizationHeader = (token = null) => {
  if (token) {
    ApiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    console.log(ApiClient.defaults.headers.common["Authorization"]);
  } else {
    delete ApiClient.defaults.headers.common["Authorization"];
  }
};

export default setAuthorizationHeader;
