import ApiClient from "config/ApiClient";

export const getMe = async () => {
  return await ApiClient.post("/auth/me");
};
export const getMyFiles = async () => {
  return await ApiClient.get("/my-downloads");
};
export const getMyTransactions = async () => {
  return await ApiClient.get("/my-orders");
};
export const getDetailTransactions = async (order_code) => {
  return await ApiClient.get("/my-orders/" + order_code);
};

export const doUpdateAccount = async (image, data) => {
  let dataToSend = { ...data };
  if (image) {
    const formData = new FormData();
    formData.append("image", image);
    const imageResponse = await ApiClient.post("/medias", formData);
    const {
      data: { id: mediaId },
    } = imageResponse;
    dataToSend.profile_picture = mediaId;
  }

  return await ApiClient.put("/my-account", dataToSend);
};
