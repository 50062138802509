import { createSlice } from "@reduxjs/toolkit";

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState: {
    product: JSON.parse(localStorage.getItem("BBK:product_to_checkout"))
      ? JSON.parse(localStorage.getItem("BBK:product_to_checkout"))
      : null,
  },
  reducers: {
    setProduct: (state, action) => {
      state.product = action.payload;
    },
  },
});

export const { setProduct } = checkoutSlice.actions;

export default checkoutSlice.reducer;
