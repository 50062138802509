import ApiClient from "config/ApiClient";

export const getCourses = async () => {
  return await ApiClient.get("/courses");
};
export const getCourseDetail = async (slug) => {
  return await ApiClient.get(`/courses/${slug}`);
};

export const getMyCourses = async () => {
  return await ApiClient.get("/my-courses");
};

export const getMyCourse = async (course_id) => {
  return await ApiClient.get("/my-courses/" + course_id);
};

export const getLearningCourse = async (slug) => {
  return await ApiClient.get(`/courses/${slug}/learning`);
};

export const updateFinishedLessons = async (course_id, finishedLessons) => {
  return await ApiClient.put("/my-courses/update-finished-lessons", {
    course_id: course_id,
    finished_lessons: finishedLessons,
  });
};

export const updateFinishedQuiz = async (course_id, finishedQuiz) => {
  return await ApiClient.put("/my-courses/update-finished-quiz", {
    course_id: course_id,
    finished_quiz: finishedQuiz,
  });
};

export const submitQuizAnswers = async (data) => {
  return await ApiClient.post("/my-courses/submit-quiz", data);
};

export const getQuizAnswers = async (quizId) => {
  return await ApiClient.get("/my-courses/get-quiz-answer/" + quizId);
};

export const purgeQuizAnswers = async () => {
  return await ApiClient.delete(
    "/test/purge-user-answers?email=mmalbiruni83@gmail.com"
  );
};
