import ApiClient from "config/ApiClient";

const authAPI = {
  login: (data) => ApiClient.post("/auth/login", data),
  refresh: () => ApiClient.post("/auth/refresh"),
  logout: () => ApiClient.post("/auth/logout"),
  me: () => ApiClient.post("/auth/me"),
  register: (data) => ApiClient.post("/auth/register", data),
};

export default authAPI;
