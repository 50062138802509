import { createSlice } from "@reduxjs/toolkit";
import { getUserDataInLocalStorage } from "utils";
const data_from_localstroage = getUserDataInLocalStorage();
const initialState = {
  isAuth: false,
  token:
    data_from_localstroage && data_from_localstroage.access_token
      ? data_from_localstroage.access_token
      : null,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, payload) => {
      state.isAuth = payload.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuth, setUser } = authSlice.actions;

export default authSlice.reducer;
