import ApiClient from "axios";
import errorHandler from "./errorHandler";

// const ApiClient = axios.create({
//   baseURL: process.env.REACT_APP_BACKEND_HOST_API_URL,
//   headers: {
//     Accept: "Application/json",
//     // Authorization: `Bearer ${session.access_token}`,
//   },
//   withCredentials: true,
// });
ApiClient.defaults.baseURL = process.env.REACT_APP_BACKEND_HOST_API_URL;

export function setAuthorizationHeader() {
  const session = localStorage.getItem("BBK:token")
    ? JSON.parse(localStorage.getItem("BBK:token"))
    : null;
  if (session) {
    ApiClient.defaults.headers.common.Authorization = `Bearer ${session.access_token}`;
  } else {
    delete ApiClient.defaults.headers.common.Authorization;
  }
}

ApiClient.interceptors.response.use((result) => result.data, errorHandler);

export default ApiClient;
