import ApiClient from "config/ApiClient";

export const getProductsByCategorySlug = async (category = "") => {
  return await ApiClient.get(`/products?category=${category}`);
};

export const getProduct = async (slug = "") => {
  return await ApiClient.get(`/products/${slug}`);
};

export const getMyProductsByCategorySlug = async (category = "") => {
  return await ApiClient.get(`/my-products?category=${category}`);
};
