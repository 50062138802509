import { getMe } from "../api/userAPI";

export const renderAssetUrl = (file, path = "uploads") => {
  if (!file) {
    return null;
  }
  return `${process.env.REACT_APP_BACKEND_HOST_URL}/storage/${path}/${file}`;
};

export const renderAvatarNameImage = (name) => {
  return `https://ui-avatars.com/api/?name=${name}`;
};

export const getAvatarImage = (name, realImage) => {
  if (realImage) {
    return renderAssetUrl(realImage);
  }
  return renderAvatarNameImage(name);
};

export const formatThousand = (number) => {
  return new Intl.NumberFormat().format(number);
};

export const formatDate = (date) => {
  return new Date(date).toLocaleString();
};

export const renderYoutubeEmbedUrl = (url) => {
  const videoId = url.split("/").pop();

  return `https://www.youtube.com/embed/${videoId}`;
};

export function renderButtonTextWhenLoading(
  loading,
  loadingText = "Loading...",
  buttonText = "Lanjutkan"
) {
  return loading ? loadingText : buttonText;
}

export const getUserDataInLocalStorage = () => {
  const data = JSON.parse(localStorage.getItem("BBK:token"))
    ? JSON.parse(localStorage.getItem("BBK:token"))
    : null;

  try {
    return data;
  } catch (error) {
    console.log(error);
    return data;
  }
};
export const updateUserDataInLocalStorage = (data) => {
  const userData = getUserDataInLocalStorage();
  const updatedData = {
    ...userData,
    ...data,
  };
  localStorage.setItem("BBK:token", JSON.stringify(updatedData));
};

export const isImage = (file) => {
  return file["type"].split("/")[0] == "image"; //returns true or false
};

export const isMax1MB = (file) => {
  const maxSize = 1024 * 1024; // 1MB in bytes

  if (!file) {
    return false;
  }
  if (file.size >= maxSize) {
    return false;
  }

  return true;
};

export const getUnixTimestamp = (dateString = "") => {
  if (!dateString) return null;
  const timestampMilliseconds = Date.parse(dateString);
  const timestampSeconds = Math.floor(timestampMilliseconds / 1000);
  return timestampSeconds;
};
export const getCurrentUnixTimestamp = () => {
  const currentTimestampMilliseconds = Date.now();
  const currentUnixTimestampSeconds = Math.floor(
    currentTimestampMilliseconds / 1000
  );
  return currentUnixTimestampSeconds;
};

export const compareTimestamp = (date) => {
  const currTime = getCurrentUnixTimestamp();
  const myTime = getUnixTimestamp(date);

  if (currTime > myTime) {
    return true;
  }

  return false;
};
